import { TokenGenerator } from "./adapters/TokenGenerator";

class TokenGeneratorHelperClass {
  setUUIDMethod(method: () => string) {
    TokenGenerator.setUUIDMethod(method);
  }
  uuid() {
    return TokenGenerator.uuid();
  }
}

const TokenGeneratorHelper = new TokenGeneratorHelperClass();
export { TokenGeneratorHelper };
