import { TokenGeneratorImplementation } from "./TokenGenerator.implementation";

const instance = new TokenGeneratorImplementation();
export class TokenGenerator {
  static setUUIDMethod(method: () => string) {
    instance.setUUIDMethod(method);
  }
  static uuid() {
    return instance.uuid();
  }
}
