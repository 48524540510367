import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";

export function phoneFormatting(value: string): string {
  if (value.startsWith("+")) {
    return new AsYouType().input(value);
  } else {
    return new AsYouType("US").input(value);
  }
}

export function phoneValidation(phoneNumberString: string): boolean {
  const input = phoneNumberString.startsWith("+")
    ? phoneNumberString
    : `+1${phoneNumberString}`;
  const phoneNumber = parsePhoneNumberFromString(input);

  if (phoneNumber) {
    return phoneNumber.isValid();
  } else {
    return false;
  }
}

export function phoneNumberValue(value: string): string | undefined {
  const input = value.startsWith("+") ? value : `+1${value}`;
  const phoneNumber = parsePhoneNumberFromString(input);

  return phoneNumber && phoneNumber.format("E.164");
}
