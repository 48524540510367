// Keeping those 2 lines in case we encounter compatibility issues with the `global.fetch` API.
// import "url-search-params-polyfill"; // for IE :( https://caniuse.com/#feat=urlsearchparams
// import "isomorphic-fetch"; // for NodejS (?)

import { TokenGeneratorHelper } from "./TokenGeneratorHelper";
import { EnvironmentHelper } from "./EnvironmentHelper";
import { AuthStrategy, getAuthHeaders } from "./AuthHelper/AuthHeaders";

export type WebServiceError = {
  errors: {
    [key: string]: Array<string>;
  };
  message: string;
};

export type CallbackParams = {
  response?: any;
  error?: WebServiceError;
  endPoint: string;
  method: string;
  params: any;
  uuid: string;
  fetchCompleted: boolean;
};

export type RequestCycleCallback = (params: CallbackParams) => void;

type FetchParamsGet = {
  endPoint: string;
  params?: any;
  timeout?: number;
};
type FetchParams = {
  endPoint: string;
  params: any;
  timeout?: number;
};

export class NetworkHelperClass {
  private successCallbacks: Array<Function> = [];
  private errorCallbacks: Array<Function> = [];
  private startCallbacks: Array<Function> = [];
  private endCallbacks: Array<Function> = [];
  private customURL: string = "";
  token: string = "";
  headers: any = {};
  authStrategy = AuthStrategy.Legacy;

  constructor({ headers }: { headers?: { [key: string]: string } } = {}) {
    this.token = "9a75e7b893e154c8afd7f092187d973f";
    this.headers = headers || {
      "Content-Type": "application/json",
      Authorization: `Token token=${this.token}`,
      Accept: "application/json.v2"
    };
  }

  set baseURL(url: string) {
    this.customURL = url;
  }

  get baseURL() {
    return this.customURL || EnvironmentHelper.getEnvironmentURL();
  }

  onStart(callback: RequestCycleCallback) {
    this.startCallbacks.push(callback);
  }

  onEnd(callback: RequestCycleCallback) {
    this.endCallbacks.push(callback);
  }

  onSuccess(callback: RequestCycleCallback) {
    this.successCallbacks.push(callback);
  }

  onError(callback: RequestCycleCallback) {
    this.errorCallbacks.push(callback);
  }

  // params are any because of problem
  // https://github.com/microsoft/TypeScript/issues/15300
  //
  // TS doesn't realize that APIParams = { [key: string]: string | number } is a superset of PayerInfo
  // a better solution using generics might exist

  async get({ endPoint, params, timeout }: FetchParamsGet) {
    return this.fetch({
      endPoint,
      method: "GET",
      params,
      timeout
    });
  }

  async post({ endPoint, params, timeout }: FetchParams) {
    return this.fetch({
      endPoint,
      method: "POST",
      params,
      timeout
    });
  }

  async put({ endPoint, params, timeout }: FetchParams) {
    return this.fetch({
      endPoint,
      method: "PUT",
      params,
      timeout
    });
  }

  async fetch({
    endPoint,
    method,
    params,
    timeout
  }: {
    endPoint: string;
    method: string;
    params?: any;
    timeout?: number;
  }) {
    let response,
      error,
      fetchCompleted = false;
    const uuid = TokenGeneratorHelper.uuid();
    try {
      for (const callback of this.startCallbacks) {
        callback({ endPoint, method, params, uuid });
      }

      response = await this.fetchRawResponse({
        endPoint,
        method,
        params,
        timeout
      });

      fetchCompleted = true;

      let res;
      try {
        res = await response.json();
      } catch (error) {
        res = { success: false, error, data: response.body };
      }

      res.status_code = response.status;
      if (response.status >= 500) {
        throw res;
      }
      for (const callback of this.successCallbacks) {
        callback({ response, endPoint, method, params, uuid, fetchCompleted });
      }
      return res;
    } catch (err) {
      error = err;
      for (const callback of this.errorCallbacks) {
        callback({ error, endPoint, method, params, uuid, fetchCompleted });
      }
      console.error(error);
      throw error;
    } finally {
      for (const callback of this.endCallbacks) {
        callback({
          response,
          error,
          endPoint,
          method,
          params,
          uuid,
          fetchCompleted
        });
      }
    }
  }

  async fetchRawResponse({
    endPoint,
    method,
    params,
    timeout = 30000
  }: {
    endPoint: string;
    method: string;
    params?: any;
    timeout?: number;
  }) {
    let body: string | null = null,
      query: string | null = null;

    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeout);

    if (params) {
      if (method.toUpperCase() !== "GET") {
        body = !params ? null : JSON.stringify(params);
      } else {
        query = this.makeSearchString(params);
      }
    }

    // support passing-in full URLs
    const url = endPoint.startsWith("http")
      ? endPoint
      : `${this.baseURL}${endPoint.startsWith("/") ? "" : "/"}${endPoint}`;

    return fetch(`${url}${query ? query : ""}`, {
      signal: abortController.signal,
      method: method,
      headers: {
        ...this.headers,
        ...getAuthHeaders(this.authStrategy)
      },
      body
    });
  }

  private makeSearchString(params: any) {
    let ret = Object.entries(params).reduce(
      (acc, [key, value]) => `${acc}&${key}=${value}`,
      `time_now=${new Date().getTime()}`
    );

    return ret ? `?${ret}` : ret;
  }
}

export const NetworkHelper = new NetworkHelperClass();
